import React from "react"
import styled from "styled-components/macro"
import tw from "twin.macro"
import Seo from "../components/Seo"
import NpoSvlLogo from "../components/NpoSvlLogo"
import { IndexLayout } from "../components/IndexLayout"

const about = [
  {
    title: "Миссия ООО «НПО СВЛ»",
    items: [
      "участие в цифровой трансформации железнодорожного транспорта в Российской Федерации путем оснащения тягового и моторвагонного подвижного состава современными бортовыми системами собственного производства для решения задач мониторинга технического состояния и повышения эффективности использования парка",
    ],
  },
  {
    title: "Наши преимущества",
    items: [
      "коллектив профессионалов",
      "собственные производственные мощности в городе Москва",
      "наработанная годами промышленная кооперация с предприятиями Урала, Москвы и Московской области",
    ],
  },
  {
    title: "Специализация",
    items: [
      "разработка и производство оборудования для железнодорожного транспорта",
    ],
  },
  {
    title: "Наши приоритеты",
    items: [
      "разработка инновационного оборудования",
      "непрерывная работа над качеством продукции",
      "выполнение обязательств",
    ],
  },
]

const AboutItem = styled.div`
  ${tw`w-full sm:w-1/2 mb-6 sm:mb-8 text-xs`}
  &:nth-child(2n-1) {
    ${tw`sm:pr-6`}
  }
  &:nth-child(2n) {
    ${tw`sm:pl-6`}
  }
  h3 {
    ${tw`uppercase text-blue-700 font-display`}
  }
  ul {
    ${tw`list-disc list-inside text-xs`}
  }
  li {
    ${tw`text-gray-400`}
    color: rgb(103 91 96 / 86%);
    span {
      ${tw`text-gray-800`}
    }
  }
`

const IndexPage = () => (
  <IndexLayout>
    <Seo title="Главная" />

    <div className="flex justify-center mt-6 mb-12">
      <NpoSvlLogo />
    </div>
    <div className="flex flex-wrap justify-between md:w-3/4 xl:w-1/2 m-auto">
      {about.map((x) => (
        <AboutItem key={x.title} className="about-item">
          <h3>{x.title}</h3>
          <ul>
            {x.items.map((i, index) => (
              <li key={index}>
                <span>{i}</span>
              </li>
            ))}
          </ul>
        </AboutItem>
      ))}
    </div>
  </IndexLayout>
)

export default IndexPage
