import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
//@ts-ignore
import SvgText from "../images/svl-text.svg"

const NpoSvlLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      npoSvlImage: file(relativePath: { eq: "logo-svl.png" }) {
        childImageSharp {
          fluid(maxWidth: 255, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div style={{ width: 255 }}>
      <Img fluid={data.npoSvlImage.childImageSharp.fluid} />
      <img src={SvgText} alt="НПО СВЛ" />
    </div>
  )
}

export default NpoSvlLogo
