/// <reference types="styled-components/cssprop" />
import React from "react"
import { Nav } from "../Nav"
import { Main } from "../Main.styles"
import { IndexFooter } from "./IndexFooter"
import { css } from "styled-components/macro"
import tw from "twin.macro"

export const IndexLayout: React.FC = ({ children }) => {
  return (
    <div
      css={[
        tw`min-h-screen w-full flex flex-col`,
        css`
          background: "#f2f2f0";
        `,
      ]}
    >
      <Nav />

      <Main>{children}</Main>

      <IndexFooter />
    </div>
  )
}
