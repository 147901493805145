import React from "react"
import styled, { css } from "styled-components/macro"
import tw from "twin.macro"

const StyledFooter = styled.footer`
  ${tw`flex flex-shrink-0 flex-wrap justify-center items-center bg-gray-900 p-2 leading-loose`}
  color: gray;
  font-size: xx-small;
`

export const IndexFooter = () => {
  return (
    <StyledFooter>
      <div css={tw`mr-4`}>ООО "НПО Системы Взаимодействия с Локомотивом"</div>
      <Email />
      <PhoneNumber number="+7 (916) 750-23-33, +7 (499) 340-37-76" />
    </StyledFooter>
  )
}

function Email() {
  return (
    <div css={tw`flex items-center mr-4`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="12"
        height="12"
        css={tw`inline fill-current`}
      >
        <path
          className="heroicon-ui"
          fillRule="evenodd"
          d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"
        />
      </svg>{" "}
      info@nposvl.ru
    </div>
  )
}

function PhoneNumber({ number }: { number: string }) {
  return (
    <div css={tw`flex items-center`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="12"
        height="12"
        css={tw`inline fill-current`}
      >
        <path
          className="heroicon-ui"
          fillRule="evenodd"
          d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z"
        />
      </svg>{" "}
      {number}
    </div>
  )
}
